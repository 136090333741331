export const COUNTRY_GUIDE_WP_TYPE = 'countryGuide';

export const COUNTRY_GUIDE_URI_PREFIX = '/country-hiring';

export const getCountryGuidesQuery = ({ fieldsToFetch = '' } = {}) => {
  return `
    query CountryGuide($first: Int, $after: String, $status: [PostStatusEnum!]) {
      countryGuides(first: $first, after: $after, where: {stati: $status}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          databaseId
          slug
          uri
          meta
          title
          ${fieldsToFetch}
        }
      }
    }
  `;
};
