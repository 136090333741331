export function isNotNull<T>(value: null | T | undefined): value is T {
  return value !== null && value !== undefined;
}

export function isError(error: unknown): error is Error {
  if (!(error instanceof Error)) {
    return false;
  }

  return true;
}
