const baseConfig = {
  dsn: 'https://b0df16f6288d691c041637b799a533c8@o51008.ingest.us.sentry.io/4504081970692096',

  ignoreErrors: [
    // 404
    'Non-Error promise rejection captured with value: invalid_request',
  ],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
};

export default baseConfig;
