type NodeEnvs = 'development' | 'production' | 'test';

type DatadogEnvs = 'dev' | 'local' | 'prod';

export enum BuildEnvs {
  Development = 'development',
  Local = 'local',
  Preview = 'preview',
  Production = 'production'
}

// Not destrucuring here is terrible, but the only way to avoid it is by declaring a webpack process env plugin, and listing
// every single env var, which introduces further complications... -> https://github.com/vercel/next.js/issues/19420
const CAPTURED_NODE_ENV = process.env.NODE_ENV;
const CAPTURED_VERCEL_ENV = process.env.VERCEL_ENV;
const NEXT_PUBLIC_DD_SERVICE = process.env.NEXT_PUBLIC_DD_SERVICE;
const NEXT_PUBLIC_DD_ENV = process.env.NEXT_PUBLIC_DD_ENV;
const NEXT_PUBLIC_DD_APPLICATION_ID = process.env.NEXT_PUBLIC_DD_APPLICATION_ID;
const NEXT_PUBLIC_DD_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN;
const NEXT_PRIVATE_CLEARBIT_API_KEY = process.env.NEXT_PRIVATE_CLEARBIT_API_KEY;
const NEXT_PRIVATE_XERO_CLIENT_ID = process.env.XERO_CLIENT_ID;
const NEXT_PRIVATE_XERO_APP_SECRET = process.env.XERO_APP_SECRET;

export const NODE_ENV = (CAPTURED_NODE_ENV || 'development') as NodeEnvs;

// vercel env doubles as "build env" (what build env this is running in, local, stage, prod)
export const VERCEL_ENV = (CAPTURED_VERCEL_ENV || 'local') as BuildEnvs;

export const DD_SERVICE =
  NEXT_PUBLIC_DD_SERVICE || 'rippling-marketing-website';

export const DD_ENV: DatadogEnvs = (NEXT_PUBLIC_DD_ENV ||
  'local') as DatadogEnvs;

export const DD_APPLICATION_ID = NEXT_PUBLIC_DD_APPLICATION_ID as string;

export const DD_CLIENT_TOKEN = NEXT_PUBLIC_DD_CLIENT_TOKEN as string;

export const CLEARBIT_API_KEY = NEXT_PRIVATE_CLEARBIT_API_KEY as string;

export const XERO_CLIENT_ID = NEXT_PRIVATE_XERO_CLIENT_ID as string;

export const XERO_APP_SECRET = NEXT_PRIVATE_XERO_APP_SECRET as string;

export const PREVIEW_SECRET = process.env.PREVIEW_SECRET as string;

export const BUILD_ID = process.env.BUILD_ID as string;

export const OPENPRISE_ENDPOINT = process.env.OPENPRISE_ENDPOINT as string;

export const OPENPRISE_API_KEY = process.env.OPENPRISE_API_KEY as string;

export const OPENPRISE_POD_NAME = process.env.OPENPRISE_POD_NAME as string;

export const OPENPRISE_SNOWFLAKE_PROXY = process.env
  .OPENPRISE_SNOWFLAKE_PROXY as string;
