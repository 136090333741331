export enum FormField {
  CompanyName = 'companyName',
  ContactUsReason = 'contactUsReason',
  Email = 'email',
  FullName = 'fullName',
  HeadquartersOrCountry = 'headquartersOrCountry',
  Industry = 'industry',
  NumberOfEmployees = 'numberOfEmployees',
  PhoneNumber = 'phoneNumber',
  ReferrerSource = 'referrerSource',
  ReferringPartner = 'referringPartner',
  State = 'state',
  Title = 'title'
}

export enum FormType {
  ContactUs = 'Contact Us',
  Content = 'Content',
  DemoDropOff = 'Demo Drop Off',
  DemoRequest = 'Demo Request',
  PEODemoRequest = 'PEO Demo Request',
  ProductTour = 'Product Tour',
  QuoteRequest = 'Quote Request',
  SelfGuidedTour = 'Self-Guided Tour',
  VideoTour = 'Video Tour',
  Webinar = 'Webinar'
}

// Mapping from form field to Openprise field
export const formFieldToOpenpriseMapping = {
  [FormField.CompanyName]: 'Company',
  [FormField.ContactUsReason]: 'Contact_Us_Reason__c',

  // Form fields
  [FormField.Email]: 'Email',
  [FormField.FullName]: 'FullName',
  [FormField.HeadquartersOrCountry]: 'Country',
  [FormField.Industry]: 'Industry',
  [FormField.NumberOfEmployees]: 'NumberOfEmployees',
  [FormField.PhoneNumber]: 'Phone',
  [FormField.ReferrerSource]: 'ReferrerSource',
  [FormField.ReferringPartner]: 'Referring_Partner__c',
  [FormField.State]: 'State',
  [FormField.Title]: 'Title',
} as const;

export type OpenpriseFieldKey =
  | 'cid'
  | 'FirstName'
  | 'Form_Type__c'
  | 'Last_Lead_Origin__c'
  | 'LastName'
  | (typeof formFieldToOpenpriseMapping)[keyof typeof formFieldToOpenpriseMapping];
