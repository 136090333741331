// @TODO Deprecate
export const get30Days = () => {
  const date = new Date();

  return new Date(date.setDate(date.getDate() + 30));
};

export const getXDayFromNow = (x: number) => {
  const date = new Date();

  return new Date(date.setDate(date.getDate() + x));
};

export const getXHoursFromNow = (h: number) => {
  const date = new Date();
  date.setTime(date.getTime() + h * 60 * 60 * 1000);

  return date;
};

export const dateToDateStr = (date: null | string) => {
  if (date) {
    let [
      month,
      day,
      year,
    ] = date.split(' ');

    const MONTHS = {
      April: '04',
      August: '08',
      December: '12',
      February: '02',
      January: '01',
      July: '07',
      June: '06',
      March: '03',
      May: '05',
      November: '11',
      October: '10',
      September: '09',
    };

    day = day.match(/\d+/)[0];
    day = Number.parseInt(day, 10) < 10 ? `0${day}` : day;

    return `${year}-${MONTHS[month]}-${day}`;
  }
};
