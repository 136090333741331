import fs from 'fs';
import path from 'path';
import { twMerge } from 'tailwind-merge';

import { LOCALES } from '../locales';
import {
  fetchApiWithRetries, parseJSONS, shouldFetchPost
} from './_helpers';
import { localize } from './_wordpress';

const isNonEmptyArray = (arr) => {
  if (!Array.isArray(arr)) {
    return false;
  }

  return arr.length > 0;
};

const isString = (str) => {
  return typeof str === 'string';
};

const isNonEmptyString = (str) => {
  if (!isString(str)) {
    return false;
  }

  return str.length > 0;
};

// import from ts file once Wordpress can support
const getWpLocaleEnum = (locale) => {
  const [lang, country] = locale.split('-');

  return `${lang.toUpperCase()}_${country.toUpperCase()}`;
};

export const fetchReusable = async (slug, locale) => {
  const data = await fetchApiWithRetries(
    `
    query Reusable($slug: ID!, $locale: LocaleEnum) {
      reusable(id: $slug, idType: SLUG) {
        blocks(locale: $locale)
        reusableId
        slug
      }
    }
  `,
    {
      variables: {
        locale: getWpLocaleEnum(locale),
        slug,
      },
    }
  );

  if (!isNonEmptyString(data.reusable?.blocks)) {
    console.error(`Got invalid result for reusable base query (!query.reusable.blocks): "${slug}": ${data.reusable?.blocks}`);

    return null;
  }

  const blocks = JSON.parse(data.reusable.blocks);

  if (!isNonEmptyArray(blocks)) {
    console.error(`Got invalid result for reusable base query (!blocks.length): "${slug}": ${blocks}`);

    return null;
  }

  data.blocks = blocks;

  return data;
};

const pullReusableFromPrebuildCache = (slug, locale) => {
  try {
    const postJSON = fs.readFileSync(
      path.resolve(`./cache/reusables/${slug}.json`),
      { encoding: 'utf8' }
    );

    let post = JSON.parse(postJSON);
    post = parseJSONS(post);

    post.blocks = localize(post.blocks, locale);

    return post;
  } catch {
    // console.log(`Failed to pull post ${slug} from pre-build cache`)
    return null;
  }
};

const retrieveReusable = async (slug, locale) => {
  let reusable;

  const runFetchReusable = () => fetchReusable(slug, locale);
  const runPullReusable = () => pullReusableFromPrebuildCache(slug, locale);

  if (shouldFetchPost()) {
    reusable = await runFetchReusable();
    if (!reusable) {
      reusable = runPullReusable();
    }
  } else {
    reusable = runPullReusable();
    if (!reusable) {
      reusable = runFetchReusable();
    }
  }

  return reusable;
};

const formatNextJSClientBlocks = (blocks) => {
  return blocks.map((block) => {
    block.name = block.name.replaceAll(/rgb\/|core\//gi, '');
    if (!block.attributes) {
      block.attributes = {};
    }

    // this is an unfortunate change needed to support gutenberg blocks
    const isNativeGutenbergBlock = [
      'heading',
      'paragraph',
      'list',
    ].includes(block.name);

    if (isNativeGutenbergBlock) {
      // for heading and paragraph
      if (block.attributes?.content) {
        block.attributes.innerContent = block.attributes.content;
      }

      // for list blocks
      if (block.attributes?.values) {
        block.attributes.innerContent = block.attributes.values;
      }
    }

    if (block.className) {
      block.attributes.className = block.className;
    }

    if (Array.isArray(block.innerBlocks)) {
      block.innerBlocks = formatNextJSClientBlocks(block.innerBlocks);
    }

    return block;
  });
};

const removeUnnamedBlocks = (blocks) => {
  const result = [];

  for (const block of blocks) {
    if (!block.name) {
      continue;
    }

    if (Array.isArray(block.innerBlocks)) {
      block.innerBlocks = removeUnnamedBlocks(block.innerBlocks);
    }

    result.push(block);
  }

  return result;
};

const getAddedBlockPosition = (mergedBlocks, addedBlock) => {
  const result = {
    index: 0,
    position: addedBlock.position,
  };

  // first position is always 0
  if (addedBlock.position === 'first') {
    return result;
  }

  // if merged blocks is empty, its also 0...
  if (mergedBlocks.length === 0) {
    result.position = 'first';

    return result;
  }

  const foundPositionIndex = mergedBlocks.findIndex((block) => block.attributes.reuId === addedBlock.position);

  // if we found a valid index
  if (foundPositionIndex !== -1) {
    result.index = foundPositionIndex + 1;

    return result;
  }

  // if we werent able to find a positional block, lets assume it was the last
  const lastBlock = mergedBlocks.at(-1);
  result.position = lastBlock.attributes.reuId;
  result.index = mergedBlocks.length;

  return result;
};

const getDeletedBlockIndex = (mergedBlocks, deletedBlock) => {
  const positionalReuId = deletedBlock.attributes?.reuId;

  // if no position, something must have gone wrong...
  if (!positionalReuId) {
    return -1;
  }

  return mergedBlocks.findIndex((block) => block.attributes.reuId === positionalReuId);
};

export const mergeBlocks = (
  savedBlocks,
  changes,
  { isNextJsClient = false } = {}
) => {
  if (isNextJsClient) {
    savedBlocks = formatNextJSClientBlocks(savedBlocks);
  }

  const { complex, simple } = changes;

  const mergedSavedBlocks = savedBlocks
    .map((savedBlock) => {
      const complexBlock = complex?.find((changeBlock) =>
        changeBlock.attributes.reuId === savedBlock.attributes.reuId);
      let simpleBlock = simple?.[savedBlock.attributes.reuId];

      savedBlock.innerBlocks = mergeBlocks(
        savedBlock.innerBlocks,
        {
          complex: complexBlock?.innerBlocks || [],
          simple,
        },
        { isNextJsClient }
      );
      if (!simpleBlock) {
        return savedBlock;
      }

      if (isNextJsClient) {
        // we have to give the simple block a name for this to work
        simpleBlock.name = savedBlock.name;

        // we need to realign the simple block attrs (content -> innerContent)
        simpleBlock = formatNextJSClientBlocks([simpleBlock])[0];
      }

      for (const prop of Object.keys(savedBlock)) {
        if (prop !== 'attributes' && prop !== 'innerBlocks') {
          savedBlock[prop] =
            simpleBlock[prop] === undefined
              ? savedBlock[prop]
              : simpleBlock[prop];
        }

        if (prop === 'attributes') {
          const newAttributes = {};

          for (const attribute of Object.keys(savedBlock[prop])) {
            newAttributes[attribute] = savedBlock[prop][attribute];
          }

          for (const attribute of Object.keys(simpleBlock[prop])) {
            if (isNextJsClient && attribute === 'className') {
              newAttributes.className = twMerge(
                savedBlock.attributes.className,
                simpleBlock.attributes.className
              );
            } else {
              newAttributes[attribute] =
                simpleBlock.attributes[attribute] === undefined
                  ? savedBlock.attributes[attribute]
                  : simpleBlock.attributes[attribute];
            }
          }

          savedBlock.attributes = newAttributes;
        }
      }

      return savedBlock;
    })
    .filter((block) => !!block);

  if (complex) {
    // handle added blocks
    let addedBlocks = complex.filter((changeBlock) => changeBlock.added);
    if (isNextJsClient) {
      addedBlocks = formatNextJSClientBlocks(addedBlocks);
    }

    for (const addedBlock of addedBlocks) {
      const {
        index: insertionIndex,
        position: positionalReuId,
      } = getAddedBlockPosition(mergedSavedBlocks, addedBlock);

      addedBlock.position = positionalReuId;

      mergedSavedBlocks.splice(insertionIndex, 0, addedBlock);
    }

    // handle deleted blocks
    const deletedBlocks = complex.filter((changeBlock) => changeBlock.deleted);

    for (const deletedBlock of deletedBlocks) {
      const deletionIndex = getDeletedBlockIndex(
        mergedSavedBlocks,
        deletedBlock
      );

      // if block not found, something must have gone wrong...
      if (deletionIndex === -1) {
        continue;
      }

      mergedSavedBlocks.splice(deletionIndex, 1);
    }
  }

  return mergedSavedBlocks;
};

export const resolveReusables = async (
  blocks,
  isInnerReusable,
  locale = LOCALES.EN_US
) => {
  // lets avoid some work and possible errors if blocks is invalid...
  if (!Array.isArray(blocks)) {
    return [];
  }

  const result = [];

  for (let block of blocks) {
    if (block.name === 'reusable-2') {
      const changes = block.attributes.changes || {
        complex: [],
        simple: {},
      };

      const { slug } = block.attributes;

      // Resolve all the reusables
      const reusableBase = await retrieveReusable(slug, locale);

      // skip any not-found reusables
      if (!reusableBase) {
        continue;
      }

      let savedBlocks = reusableBase.blocks;

      if (block.innerBlocks) {
        block.innerBlocks = await resolveReusables(
          block.innerBlocks || [],
          true,
          locale
        );
      }

      // Merge changes if it is the outermost (fuction is setup so that all reusables will be resolved before merge)
      savedBlocks = removeUnnamedBlocks(savedBlocks);

      if (!isInnerReusable) {
        block = mergeBlocks(savedBlocks, changes, { isNextJsClient: true })[0];
      }
    } else if (block.innerBlocks) {
      block.innerBlocks = await resolveReusables(
        block.innerBlocks || [],
        isInnerReusable,
        locale
      );
    }

    result.push(block);
  }

  return result;
};
