export const RECIPE_WP_TYPE = 'recipe';

export const RECIPE_URI_PREFIX = '/recipes';

export const getRecipesQuery = ({ fieldsToFetch = '' } = {}) => {
  return `
    query Recipes($first: Int, $after: String, $status: [PostStatusEnum!]) {
      recipes(first: $first, after: $after, where: {stati: $status}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          databaseId
          slug
          uri
          meta
          title
          ${fieldsToFetch}
        }
      }
    }
  `;
};
