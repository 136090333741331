import { datadogRum } from '@datadog/browser-rum';

import {
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_ENV,
  DD_SERVICE
} from '../constants/env';

export function initDatadogRum() {
  datadogRum.init({
    applicationId: DD_APPLICATION_ID,
    clientToken: DD_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    env: DD_ENV,
    service: DD_SERVICE,
    sessionReplaySampleRate: 20,
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
  });

  datadogRum.startSessionReplayRecording();
}
