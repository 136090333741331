export const GLOSSARY_TERM_WP_TYPE = 'glossaryTerm';

export const GLOSSARY_TERM_URI_PREFIX = '/glossary';

export const getGlossaryTermsQuery = ({ fieldsToFetch = '' } = {}) => {
  return `
    query GlossaryTerms($first: Int, $after: String, $status: [PostStatusEnum!]) {
      glossaryTerms(first: $first, after: $after, where: {stati: $status}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          databaseId
          slug
          uri
          meta
          title
          ${fieldsToFetch}
        }
      }
    }
  `;
};
