export const REGEX_BUSINESS_EMAIL =
  /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)..+)(.+@.+..+)$/;

// https://emailregex.com/
export const REGEX_EMAIL =
  // eslint-disable-next-line no-control-regex
  /(?:[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F!\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[\da-z](?:[\da-z-]*[\da-z])?\.)+[\da-z](?:[\da-z-]*[\da-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\da-z-]*[\da-z]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)])/;

export const validateEmail = (email: string) => {
  return REGEX_EMAIL.test(email);
};

export const validateBusinessEmail = (email: string) => {
  if (!validateEmail(email)) {
    return false;
  }

  return REGEX_BUSINESS_EMAIL.test(email);
};
