export const BLOG_POST_WP_TYPE = 'post';

export const BLOG_POST_URI_PREFIX = '/blog';

export const getBlogPostsQuery = ({ fieldsToFetch = '' } = {}) => {
  return `
    query Posts($first: Int, $after: String, $status: [PostStatusEnum!]) {
      posts(first: $first, after: $after, where: {stati: $status}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          databaseId
          tags {
            nodes{
              id
              name
            }
          }
          slug
          uri
          meta
          title
          ${fieldsToFetch}
        }
      }
    }
  `;
};

/**
 * Same blog posts with multiple locales are using the same slug appended with "-\d" at the end
 */
export const getPublishedSlug = (slug) => {
  return /part-\d+$/.test(slug) ? slug : slug.replace(/-\d+$/, '');
};
