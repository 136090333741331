import { useEffect } from 'react';

import { isNotNull } from '@rippling/utils';

import useQueryParam from './useQueryParam';

export default function useTranscend() {
  const [preferences] = useQueryParam('preferences');

  useEffect(() => {
    if (!isNotNull(preferences) || !window.transcend) {
      return;
    }

    window.transcend.ready(() => {
      setTimeout(() => {
        window.transcend.showConsentManager({ viewState: 'CompleteOptions' });
      }, 4000);
    });
  }, [preferences]);
}
