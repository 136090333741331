export const checkIsDevelopment = () => {
  if (process.env.FORCE_ENV_MODE === 'production') {
    return false;
  }

  return (
    process.env.NODE_ENV === 'development' ||
    process.env.VERCEL_ENV === 'preview'
  );
};

/**
 * This file import `fs` indicating a server-side helper. However, this function is used in the client-side across WP
 * and NextJS applications.
 */
export async function fetchAPI(query, { variables } = {}, forceProd) {
  let wpUrl = process.env.WP_URL;
  let refreshToken = '';

  if (!wpUrl) {
    const isDevelopment = checkIsDevelopment();

    if (isDevelopment && !forceProd) {
      refreshToken = process.env.WP_AUTH_REFRESH_TOKEN_STAGING;
      wpUrl = process.env.WP_STAGING_URL;
    } else {
      refreshToken = process.env.WP_AUTH_REFRESH_TOKEN_PROD;
      wpUrl = process.env.WP_PROD_URL;
    }
  }

  try {
    const res = await fetch(
      `${wpUrl || 'https://live-rippling-hub.pantheonsite.io'}/graphql`,
      {
        body: JSON.stringify({
          query,
          variables,
        }),
        headers: {
          Authorization: refreshToken ? `Bearer ${refreshToken}` : undefined,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    );

    if (!res.ok) {
      const errorText = await res.text();
      throw new Error(`HTTP error ${res.status}: ${res.statusText} - ${errorText}`);
    }

    const json = await res.json();

    if (json.errors) {
      const errorMessages = json.errors
        .map((error) => error.message)
        .join(', ');
      console.error('GraphQL Errors:', errorMessages, query);
      throw new Error(`GraphQL Error: ${errorMessages}`);
    }

    return json.data;
  } catch (error) {
    console.error('Fetch API Error:', error);
    throw new Error(`Fetch API Error: ${error.message}`);
  }
}
