export const CUSTOMER_SPOTLIGHT_WP_TYPE = 'customerSpotlight';

export const CUSTOMER_SPOTLIGHT_URI_PREFIX = '/customers';

export const getCustomerSpotlightsQuery = ({ fieldsToFetch = '' } = {}) => {
  return `
    query CustomerSpotlights($first: Int, $after: String, $status: [PostStatusEnum!]) {
      customerSpotlights(first: $first, after: $after, where: {stati: $status}) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          databaseId
          slug
          uri
          meta
          title
          ${fieldsToFetch}
        }
      }
    }
  `;
};
